<template>
  <div class="wx_login"></div>
</template>

<script>
export default {
  mounted() {
    this.$router.replace({ path: '/' })
  }
}
</script>

<style lang="scss" scoped></style>
